import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { FilterVenuesDto, FindOneVenueDto } from '../../gateways/venues.gateway'
import { VenueFilter } from '../../models/venues/venues-filter'
import { VenueDetail } from '../../models/venues/venues-details'

export const VenueActions = createActionGroup({
	source: 'Venue',
	events: {
		'Clean Filter': emptyProps(),
		'Apply Filter': emptyProps(),
		'Get Venues': emptyProps(),
		'Load Venues': props<{ filterVenuesDto: FilterVenuesDto }>(),
		'Load Venues Success': props<{ venues: VenueFilter[] }>(),
		'Load Venues Fail': props<{ error: string }>(),
		'Filter Venues By Name': props<{ filterVenuesDto: FilterVenuesDto }>(),
		'Filter Venues By Name V2': props<{ name: string }>(),
		'Filter Venues By Name Success': props<{ venuesFilter: VenueFilter[] }>(),
		'Filter Venues By Name Fail': props<{ error: string }>(),
		'Clean Filter Venues By Name': emptyProps(),
		'Get One Venue': props<{
			venueId: string
			findOneVenueDto?: FindOneVenueDto
		}>(),
		'Load One Venue': props<{
			venueId: string
			findOneVenueDto?: FindOneVenueDto
		}>(),
		'Load One Venue Success': props<{ venue: VenueDetail }>(),
		'Load One Venue Fail': props<{ error: string }>(),
		'Load More Venue': emptyProps(),
		'Load More Venue Success': props<{ venues: VenueFilter[] }>(),
		'Load More Venue Fail': props<{ error: string }>(),
		'Set Location': props<{ latitude: number; longitude: number; maxDistance: number }>(),
	},
})
