import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { switchMap, map, mergeMap, catchError, of } from 'rxjs'
import {
	ConfirmReservationDto,
	OrderGateway,
	ResponseAvailabilityReservation,
} from '../../gateways/order.gateway'
import { ReservationActions } from './reservation.actions'
import { getClient } from '../client/client.selectors'
import { getOrderIdAndVenueId, getConfirmReservation, getSections } from './reservation.selectors'
import { Section } from './reservation'

@Injectable()
export class ReservationEffects {
	private actions$ = inject(Actions)
	private orderGateway = inject(OrderGateway)
	private store = inject(Store)

	setScheduleFromPartysize$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ReservationActions.setSelectedSection),
			concatLatestFrom(() => this.store.select(getSections)),
			map(([action, sections]) => {
				const section = sections?.find(sc => sc.label === action.selectedSectionLabel) as Section
				return ReservationActions.setScheduleFromPartysize({
					selectedPartySize: String(section.schedules[0].partySize),
				})
			})
		)
	})

	loadAvailabilities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ReservationActions.loadAvailabilities),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) => {
				return this.orderGateway
					.checkAvailability({
						venueId: action.venueId,
						clientId: client?.id as string,
						categoryId: client?.selectedCategory?.categoryId as string,
						intent: 'reservation',
					})
					.pipe(
						switchMap((response: unknown) => {
							const correctResponse = response as ResponseAvailabilityReservation
							return [
								ReservationActions.loadAvailabilitiesSuccess({
									availabilities: correctResponse.availabilities,
								}),
								ReservationActions.setOrderIdAfterLoadingAvailabilities({
									orderId: correctResponse.order._id,
									venueId: action.venueId,
								}),
							]
						}),
						catchError(error =>
							of(
								ReservationActions.loadAvailabilitiesFail({
									error: error?.error?.message,
								})
							)
						)
					)
			})
		)
	})

	confirmReservation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ReservationActions.confirmReservation),
			concatLatestFrom(() => [
				this.store.select(getOrderIdAndVenueId),
				this.store.select(getConfirmReservation),
				this.store.select(getClient),
			]),
			mergeMap(([, order, confirmReservationState, client]) =>
				this.orderGateway
					.confirmOrder(order.orderId!, {
						partySize: confirmReservationState.partySize,
						reservationDay: confirmReservationState.reservationDay,
						reservationTime: confirmReservationState.reservationTime,
						section: confirmReservationState.sectionId,
						sectionLabel: confirmReservationState.sectionLabel,
						clientId: client?.id,
					} as ConfirmReservationDto)
					.pipe(
						map(() => ReservationActions.confirmReservationSuccess()),
						catchError(error =>
							of(
								ReservationActions.confirmReservationFail({
									error: error?.error?.message,
								})
							)
						)
					)
			)
		)
	})
}
