import { Observable, delay, map, of, switchMap, throwError } from 'rxjs'
import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@monorepo-channels/shared/util-environments'
import { Client } from '../models/client/client'
import { Agreements, ClientProps } from '../models/client/client.interface'

@Injectable({ providedIn: 'root' })
export class ClientGateway {
	private baseURL = `${environment.channelsAPIURL}/api/v1`
	private http = inject(HttpClient)

	updateClientBackend(clientId: string, updateClientDto: UpdateClientDto) {
		return this.http.patch(`${this.baseURL}/clients/${clientId}`, updateClientDto)
	}

	loginHomologation(clientId: string) {
		return this.http
			.get<ClientProps>(`${this.baseURL}/auth/login/homologation/${clientId}`)
			.pipe(map(client => Client.create(client)))
	}

	loginClient(clientId: string): Observable<Client> {
		return this.http
			.get<ClientProps>(`${this.baseURL}/clients/${clientId}`, { headers: { clientId } })
			.pipe(map(client => Client.create(client)))
	}

	loginLiveloSSOUserInfo(access_token: string): Observable<Client> {
		return this.http
			.post<ClientProps>(`${this.baseURL}/auth/bb-livelo/token`, { access_token })
			.pipe(map(client => Client.create(client)))
	}

	loginLiveloSSO(code: string, codeVerifier: string | null): Observable<Client> {
		return this.http
			.post<ClientProps>(`${this.baseURL}/auth/bb-livelo`, { code, codeVerifier })
			.pipe(map(client => Client.create(client)))
	}

	loginUnicredSSO(unicredToken: string): Observable<Client> {
		return this.http
			.post<ClientProps>(`${this.baseURL}/auth/unicred`, { unicredToken })
			.pipe(map(client => Client.create(client)))
	}

	isLoggedIn() {
		return this.http.get(`${this.baseURL}/auth/isLoggedIn`)
	}

	encrypt(body: EncryptCardDataDto) {
		// Uncomment the scenario you want to test
		// return this.errorScenario() as unknown as Observable<{ cardData: string }>
		// return this.successScenario({ cardData: '1234321' })
		return this.http.post<{ cardData: string }>(`${this.baseURL}/payments/encrypt`, body)
	}

	createCardToken(createCard: CreateCardDto) {
		// Uncomment the scenario you want to test
		// return this.errorScenario()
		// return this.successScenario()
		return this.http.post<{ gatewayId: string; lastFourDigits: string }>(
			`${this.baseURL}/payments/create-card-token`,
			createCard
		)
	}

	removeCard(clientId: string, lastFourDigits: string) {
		// Uncomment the scenario you want to test
		// return this.errorScenario()
		return this.http.delete(`${this.baseURL}/payments/remove-card/${clientId}/${lastFourDigits}`)
	}

	updateMainCard(clientId: string, lastFourDigits: string) {
		// Uncomment the scenario you want to test
		// return this.errorScenario()
		return this.http.patch(`${this.baseURL}/payments/main-card/${clientId}/${lastFourDigits}`, {})
	}

	private successScenario(data: unknown = {}) {
		return of(data).pipe(delay(2000))
	}

	private errorScenario(): Observable<string[]> {
		return of(['1']).pipe(
			delay(1000),
			switchMap(() => throwError(() => ({ error: { message: 'error' } })))
		)
	}
}

export interface EncryptCardDataDto {
	number: string
	cvv: string
	holder: string
	expMonth: number
	expYear: number
}

export interface CreateCardDto {
	clientId: string
	cardData: string
	cpf?: string
	billingAddress: BillingAddress
	mainCard?: boolean
	categoryId: string
}

export interface BillingAddress {
	address: string
	zipCode: string
	city: string
	state: string
	country: string
}

export interface UpdateClientDto {
	phone?: string
	agreements?: Agreements
}
