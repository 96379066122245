import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { VenuesGateway } from '../../gateways/venues.gateway'
import { AppState } from '../helper.state'
import { Store } from '@ngrx/store'
import { VenueActions } from './venue.actions'
import { getLocation, getSelectedVenue, getVenueName, getVenues } from './venue.selectors'
import { catchError, filter, map, mergeMap, of } from 'rxjs'
import { getSelectedAddress } from '../address/address.selectors'
import { getSelectedNeighborhood } from '../address/address.selectors'
import { getClient } from '../client/client.selectors'
import { getSelectedCuisine } from '../cuisine/cuisine.selectors'
import { DEFAULT_CUISINE_TEXT } from '../cuisine/cuisine.state'
import { AddressActions } from '../address/address.actions'
import { GeolocationService } from '@monorepo-channels/shared/util-helpers'

@Injectable()
export class VenueEffects {
	private actions$ = inject(Actions)
	private venueGateway = inject(VenuesGateway)
	private store = inject(Store<AppState>)
	private geolocation = inject(GeolocationService)

	// Cached
	getVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.getVenues),
			concatLatestFrom(() => this.store.select(getVenues)),
			// Only load venues if the array has at least one venue
			filter(([, venues]) => !venues?.length),
			map(() => VenueActions.loadVenues({ filterVenuesDto: {} }))
		)
	})

	// Call API (not cached)
	loadVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadVenues),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.venueGateway
					.filter({
						...action.filterVenuesDto,
						categoryId: client?.selectedCategory?.categoryId,
					})
					.pipe(
						map(venues => VenueActions.loadVenuesSuccess({ venues })),
						catchError(error => of(VenueActions.loadVenuesFail({ error })))
					)
			)
		)
	})

	loadVenuesFilterByName$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.filterVenuesByName),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.venueGateway
					.filter({
						...action.filterVenuesDto,
						categoryId: client?.selectedCategory?.categoryId,
					})
					.pipe(
						map(venuesFilter => VenueActions.filterVenuesByNameSuccess({ venuesFilter })),
						catchError(error => of(VenueActions.filterVenuesByNameFail({ error })))
					)
			)
		)
	})
	// This actions dispatch another action to LoadVenues
	loadVenuesByFilters$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				VenueActions.applyFilter,
				AddressActions.setCurrentAddress,
				AddressActions.setCurrentNeighborhood,
				VenueActions.filterVenuesByNameV2,
				VenueActions.cleanFilterVenuesByName
			),
			concatLatestFrom(() => [
				this.store.select(getSelectedCuisine),
				this.store.select(getSelectedAddress),
				this.store.select(getSelectedNeighborhood),
				this.store.select(getVenueName),
			]),
			map(([, cuisine, address, neighborhood, name]) => {
				return VenueActions.loadVenues({
					filterVenuesDto: {
						name: name,
						cousine: cuisine.name === DEFAULT_CUISINE_TEXT ? undefined : cuisine._id,
						address: neighborhood ? { neighborhood } : { city: address?.city },
						venuesAlreadyInView: [],
					},
				})
			})
		)
	})

	// // Cached
	getSelectedVenue$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.getOneVenue),
			concatLatestFrom(() => this.store.select(getSelectedVenue)),
			// Only load single venue if null
			filter(([, selectedVenue]) => !selectedVenue),
			map(([action]) => VenueActions.loadOneVenue({ venueId: action.venueId }))
		)
	})

	loadOneVenue$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadOneVenue),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.venueGateway
					.getVenueId(action.venueId, {
						...action.findOneVenueDto,
						...(client?.selectedCategory?.categoryId
							? { categoryId: client.selectedCategory.categoryId }
							: {}),
					})
					.pipe(
						map(venue => VenueActions.loadOneVenueSuccess({ venue })),
						catchError(error => of(VenueActions.loadOneVenueFail({ error })))
					)
			)
		)
	})

	loadMoreVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadMoreVenue),
			concatLatestFrom(() => {
				return [
					this.store.select(getVenues),
					this.store.select(getSelectedAddress),
					this.store.select(getClient),
					this.store.select(getLocation),
				]
			}),
			mergeMap(([, venues, selectedAddress, client, location]) =>
				this.venueGateway.filter({
					address: {
						city: selectedAddress === null ? undefined : selectedAddress.city,
						...(location && { location }),
					},
					venuesAlreadyInView: venues?.map(venue => venue.id),
					categoryId: client?.selectedCategory?.categoryId,
				})
			),
			map(venues => VenueActions.loadMoreVenueSuccess({ venues })),
			catchError(error => of(VenueActions.loadMoreVenueFail({ error })))
		)
	})

	loadVenuesByLocation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.setLocation),
			concatLatestFrom(() => [this.store.select(getVenues)]),
			filter(() => this.geolocation.firstTime),
			map(([{ latitude, longitude, maxDistance }]) => {
				this.geolocation.firstTime = false
				return VenueActions.loadVenues({
					filterVenuesDto: {
						address: {
							location: { latitude, longitude, maxDistance },
						},
						venuesAlreadyInView: [],
					},
				})
			})
		)
	})
}
