import { environment } from '@monorepo-channels/shared/util-environments'
import { Address } from '../vo/address'
import { CoverURL } from '../vo/coverURL'
import { Phone, VenueDetailProps } from './venues-details.interface'
import { OperationHours } from '../vo/operation-hours'
import { Benefits } from '../benefits/benefits'

export class VenueDetail {
	_id: string
	address: Address
	name: string
	phone: Phone[]
	cuisines: string[]
	bannersURL: string[]
	hasMenu: boolean
	mapsMobile: string
	mapsDesk: string
	mapsLink!: string
	liveMenuURL: string
	descriptionLong: string
	operationHours: OperationHours
	benefits: Benefits[]
	checkin: { enabled: boolean; available: boolean; status: 'available' | 'unavailable' | 'disabled' }
	reservation?: {
		enabled: boolean
	}

	constructor(props: VenueDetailProps) {
		this._id = props._id ?? ''
		this.name = props?.name ?? ''
		this.phone = props?.phone ?? [{ name: '', value: '' }]
		this.cuisines = props?.cousines.map(cuisine => cuisine.name) ?? ['']
		this.hasMenu = props.hasMenu
		this.operationHours = new OperationHours(props.operationHours, '-')
		this.benefits = props.benefits.map(benefit => new Benefits(benefit))
		this.descriptionLong = props.descriptionLong
		this.reservation = props.reservation
		this.checkin = {
			enabled: props.checkin.enabled,
			available: props.checkin.available,
			status: !props.checkin.enabled
				? 'disabled'
				: props.checkin.enabled && !props.checkin.available
				  ? 'unavailable'
				  : 'available',
		}

		// address
		this.address = new Address(
			props?.address.city ?? '',
			props?.address.state ?? '',
			props?.address.neighborhood ?? '',
			props?.address.address ?? '',
			props?.address.number ?? ''
		)

		// coverURL
		this.bannersURL = props?.partner.images.banners.map(URL => {
			const vo = new CoverURL(URL.coverUrl, URL.optimizedCoverUrl)
			return vo.value
		})

		// maps mobile and desk
		const location = props.address.location
		const latLong = `${location[1] || 0}, ${location[0] || 0}`
		this.mapsMobile = `https://maps.googleapis.com/maps/api/staticmap?markers=color:red|${latLong}&center=${latLong}&zoom=18&key=${environment.googleMapsAPIToken}&size=343x205`
		this.mapsDesk = `https://maps.googleapis.com/maps/api/staticmap?markers=color:red|${latLong}&center=${latLong}&zoom=18&key=${environment.googleMapsAPIToken}&size=660x424`
		this.mapsLink = `https://www.google.com/maps?q=${latLong}`
		// liveMenuURL
		const alternateMenu = props.partner?.alternateMenu
		this.liveMenuURL = alternateMenu
			? `${environment.liveMenu.url}/${alternateMenu}?origin=channels`
			: `${environment.liveMenu.url}/${props?._id}?origin=channels`
	}
}
