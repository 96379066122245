import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Address } from './address'

export const AddressActions = createActionGroup({
	source: 'Address',
	events: {
		'Get Addresses': emptyProps(),
		'Load Addresses': emptyProps(),
		'Load Addresses Success': props<{ addresses: Address[] }>(),
		'Load Addresses Failure': props<{ error: string }>(),
		'Set Current Address': props<{ address: Address }>(),
		'Set Current Neighborhood': props<{ neighborhood: string }>(),
		'Clean Address': emptyProps(),
	},
})
