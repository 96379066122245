// config
export * from './lib/config/ngx-currency.provider'
// functions
export * from './lib/functions/capitalize-each-word'
export * from './lib/functions/format-date'
export * from './lib/functions/remove-accents'
export * from './lib/functions/round2decimals'

//directives
export * from './lib/directives/clickable-tooltip.directive'

// services
export * from './lib/services/local-storage.service'
export * from './lib/services/cep.service'
export * from './lib/services/geo-location.service'
export * from './lib/services/pcke.service'

// misc
export * from './lib/misc/partners.util'

// pipes
export * from './lib/pipes/format-date-hrs.pipe'
export * from './lib/pipes/format-date-weekday.pipe'
export * from './lib/pipes/format-partysize.pipe'
export * from './lib/pipes/capitalize-first.pipe'
